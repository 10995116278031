import { useMemo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
// import { getCountryCallingCode } from 'react-phone-number-input';

import { Select2, Text } from 'kolkit';
import useIntlDisplayNames from 'utils/hooks/useIntlDisplayNames';

import styles from './InputPhoneWithCountrySelector.module.scss';

const CountrySelectComponent = ({
  onChange,
  value,
  options,
  iconComponent: IconComponent,
  minWidth = 200,
  labelId = 'auth.doubleAuthentication.modal.setup.country',
  ...props
}) => {
  const translateCountry = useIntlDisplayNames('region');

  const intlOptions = useMemo(
    () =>
      options.map((option) => ({
        ...option,
        label: translateCountry(option.value),
      })),
    [translateCountry, options],
  );
  const renderItem = useCallback(
    (item) => {
      const label = item.label || item.value;
      // // If it's in the trigger, no index is passed
      // if (index === undefined) {
      //   label = `+${getCountryCallingCode(item.value)}`;
      // }
      return (
        <li key={item.value} className="flex aic gap8 fg1">
          <IconComponent country={item.value} />
          {label}
        </li>
      );
    },
    [IconComponent],
  );

  return (
    <>
      {labelId && (
        <Text resetMargin className={styles.countryLabel} style={{ minWidth }}>
          <FormattedMessage id={labelId} defaultMessage="Country" />
        </Text>
      )}
      <Select2
        {...props}
        search
        searchKey="label"
        options={intlOptions}
        onSelectItem={(selectedCountry) => {
          onChange(selectedCountry.value);
        }}
        selected={value}
        renderItem={renderItem}
        renderLabelLikeItems
        fullWidth
        className={styles.countrySelector}
      />
    </>
  );
};

export default CountrySelectComponent;
