/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, {
  cloneElement,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
  useMemo,
  useRef
} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import DOMPurify from 'dompurify';

import Button from '../Button3';
import Input from '../Input2';
import Item from '../Item';
import useSearch from '../hooks/useSearch';

import styles from './Select2.module.scss';


const StyledMenu = styled((props) => (
  <Menu {...props} />
))((props) => ({
  '& .MuiPaper-root': {
    marginTop: 4,
    border: props.themeCollapse === 'primary'
      ? '1px solid #0c2329'
      : '1px solid #CCD6E3',
    color: '#0c2329',
    boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '8px 0'
    }
  }
}));

let inputTimer = null;

const defaultRenderItemFn = item => <li key={item.value}>{item.label || item.value}</li>;

const Select2 = ({
  dataId,
  tooltip,
  options,
  renderItem: _renderItem,
  renderLabelLikeItems = false,
  onSelectItem,
  selected,
  trigger,
  label,
  labelPosition,
  placeholder,
  anchorOrigin,
  transformOrigin,
  className,
  width,
  minWidth,
  size,
  disabled,
  onOpen,
  onClose,
  maxHeight,
  fullWidth,
  search,
  searchKey,
  onSearch,
  searchLoading,
  noResultTitle,
  inputSearchPlaceholder,
  themeCollapse,
  localState,
  selectedOptionKey,
  onClear,
  theme,
  active,
  prefixIcon,
  ...rest
}) => {
  const positionRef = useRef(null);
  const inputRef = useRef(null);

  const triggerRef = useRef(null);
  const [menuWidth, setMenuWidth] = useState(0);

  const [localSelected, setLocalSelected] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const open = !!anchorEl;

  const {
    searchQuery,
    handleChange,
    result
  } = useSearch({
    data: options,
    key: searchKey
  });

  useEffect(() => {
    return () => clearTimeout(inputTimer)
  }, []);

  useLayoutEffect(() => {
    setMenuWidth(triggerRef?.current?.clientWidth)
  }, [triggerRef]);

  const handleOpen = useCallback(
    event => {
      event.stopPropagation();
      if (inputTimer) clearTimeout(inputTimer);
      if (disabled) return false;
      if (onOpen) onOpen(!!event.currentTarget);
      setAnchorEl(event.currentTarget);
      if (search) {
        inputTimer = setTimeout(
          () => inputRef?.current?.focus(),
          200
        );
      }
    },
    [disabled, onOpen, search]
  );

  const handleClose = useCallback(
    (e) => {
      e?.stopPropagation();
      setAnchorEl(null)
      if (onClose) onClose()
    },
    [onClose]
  );

  const handleClickItem = useCallback(
    async (item, event) => {
      event?.stopPropagation();
      if (item.disabled) return null;
      if (onSelectItem && !item?.noSelectItem) await onSelectItem(item, event);
      if (item?.onClick) await item?.onClick(item);
      if (localState) await setLocalSelected(item[selectedOptionKey])
      handleClose(event);
    },
    [onSelectItem, handleClose, localState, selectedOptionKey]
  );

  const renderItem = useMemo(
    () => (_renderItem || defaultRenderItemFn),
    [_renderItem],
  );

  const selectedOption = useMemo(
    () => {
      return options?.find(option => (option.value) === selected)
    },
    [selected, options]
  );

  const getDataSource = useMemo(
    () => !onSearch && search ? result : options,
    [onSearch, search, result, options]
  );

  const renderTrigger = useMemo(() => {
    if (trigger) {
      return cloneElement(trigger, {
        'data-id': dataId,
        onClick: (e) => handleOpen(e),
        tooltip,
        selected: open,
        ...(open ? { active: open } : {}),
      });
    }

    const showClearButton = selected && onClear;
    const cnIcon = cn(styles.icon, {
      [styles.clear]: showClearButton,
    });
    const cnButton = cn(styles.button, {
      [styles.open]: open,
      [styles.theme]: theme,
      [styles.isPlaceholder]: !(
        selectedOption?.label ||
        (selectedOptionKey && selectedOption?.[selectedOptionKey])
      ),
    });

    let buttonLabel ;

    if (localState && localSelected === '') {
      buttonLabel = localSelected;
    } else if (!selected) {
      buttonLabel = placeholder || label;
    } else if (renderItem && renderLabelLikeItems) {
      buttonLabel = renderItem(selectedOption);
    } else {
      buttonLabel = selectedOption?.[selectedOptionKey];
    }

    const renderLabel = !prefixIcon ? (
      buttonLabel
    ) : (
      <Item fontSize={14} icon={prefixIcon} iconTheme="regular" padding="0">
        {buttonLabel}
      </Item>
    );

    return (
      <Button
        ref={triggerRef}
        className={cnButton}
        iconClassName={cnIcon}
        reverse
        icon={showClearButton ? 'times-circle' : 'caret-circle-down'}
        iconOnClick={showClearButton ? onClear : undefined}
        iconTheme="solid"
        iconFill="var(--color-dominant-on-dominant)"
        iconSize="huge"
        iconRotate={open && !showClearButton}
        label={renderLabel}
        onClick={handleOpen}
        theme={theme}
        size={size}
        active={active || open}
        disabled={disabled}
        fullWidth={fullWidth}
        isDropdown
        dataId={dataId}
        tooltip={tooltip}
      />
    );
  }, [
    trigger,
    selected,
    onClear,
    open,
    theme,
    selectedOption,
    selectedOptionKey,
    localState,
    localSelected,
    placeholder,
    label,
    prefixIcon,
    handleOpen,
    size,
    active,
    disabled,
    fullWidth,
    dataId,
    tooltip,
    renderItem,
    renderLabelLikeItems,
  ]);

  const handleSearch = useCallback(
    ({ value }) => {
      handleChange({ value: DOMPurify.sanitize(value) });
      if (onSearch) onSearch(DOMPurify.sanitize(value));
    },
    [onSearch, handleChange]
  );

  const renderInputSearch = useMemo(
    () => search && (
      <div className={styles.input}>
        <Input
          ref={inputRef}
          placeholder={inputSearchPlaceholder}
          value={searchQuery}
          onChange={handleSearch}
          isLoading={searchLoading}
          fullWidth
          theme="secondary"
        />
      </div>
    ),
    [search, handleSearch, searchQuery, inputSearchPlaceholder, searchLoading]
  );

  const cnWrapper = cn(
    styles.wrapper,
    {
      [styles.fullWidth]: fullWidth,
      [styles.labelTop]: labelPosition === 'top'
    },
    className
  );

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={cnWrapper}>
        { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
        {label && <label className={styles.label}>{label}</label>}
        <div className={styles.triggerWrapper} ref={positionRef}>
          {renderTrigger}
          <StyledMenu
            {...rest}
            id={`kollab_select_collapse_${size}`}
            elevation={0}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: width || (fullWidth ? menuWidth : 'auto'),
                minWidth,
              }
            }}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            classes={{
              root: styles.root,
              paper: styles.paper,
            }}
          >
            {renderInputSearch}
            <div className={styles.items} style={{ maxHeight }}>
            {
              getDataSource?.map((item, index) => {
                const el = renderItem(item, index, getDataSource);
                const cnItem = cn(
                  styles.item,
                  {
                    [styles.selected]:
                      !!selected &&
                      (item.value === selected || item.id === selected),
                    [styles.disabled]: item.disabled,
                  },
                  el?.props?.className,
                );
                return cloneElement(el, {
                  className: cnItem,
                  key: item.value || item.dataId || item.label,
                  id: item.id,
                  'data-id':
                    item.dataId ||
                    (dataId ? `${dataId}-${item.value || item.label}` : null),
                  onClick: (event) => {
                    handleClickItem(item, event)
                  },
                });
              }
            )}
            </div>
            {!getDataSource?.length && (
              <Item
                padding="8px 16px"
                fontSize="1rem"
                className={styles.noResultTitle}
              >
                {noResultTitle}
              </Item>
            )}
          </StyledMenu>
        </div>
      </div>
    </ClickAwayListener>
  );
};

Select2.propTypes = {
  dataId: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      dataId: PropTypes.string,
      value: PropTypes.any
    })
  ),
  onSelectItem: PropTypes.func,
  onClear: PropTypes.func,
  selected: PropTypes.any,
  trigger: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  canClear: PropTypes.bool,
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(['top', 'left']),
  placeholder: PropTypes.string,
  renderItem: PropTypes.func,
  renderLabelLikeItems: PropTypes.bool,
  className: PropTypes.string,
  width: PropTypes.number,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  theme: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'tone', 'tertiaryNoBorder','tertiaryReverse', 'tertiaryNew', 'shopify', 'link']),
  prefixIcon: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  search: PropTypes.bool,
  inputSearchPlaceholder: PropTypes.string,
  searchKey: PropTypes.string,
  noResultTitle: PropTypes.string,
  localState: PropTypes.bool,
  selectedOptionKey: PropTypes.string,
  themeCollapse: PropTypes.oneOf(['primary', 'secondary']),
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(['top', 'center', 'bottom']),
    horizontal: PropTypes.oneOf(['left', 'center', 'right'])
  }),
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(['top', 'center', 'bottom']),
    horizontal: PropTypes.oneOf(['left', 'center', 'right'])
  })
};

Select2.defaultProps = {
  dataId: null,
  options: [],
  size: 'medium',
  theme: 'secondary',
  prefixIcon: '',
  labelPosition: 'left',
  trigger: null,
  placeholder: '',
  fullWidth: false,
  renderItem: null,
  renderLabelLikeItems: false,
  maxHeight: 182,
  search: false,
  inputSearchPlaceholder: null,
  className: null,
  themeCollapse: 'primary',
  searchKey: 'value',
  localState: false,
  disabled: false,
  selectedOptionKey: 'label',
  noResultTitle: 'No result',
  onClose: () => {},
  onOpen: () => {},
  onSelectItem: () => {},
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  }
};

export default memo(Select2);
