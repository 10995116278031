import sha512 from 'utils/hmacSha512';

import { conf } from 'config/env';

export const getValidationHash = async (payloadData: object) => {
  if (!conf.sha512SecretKey)
    throw new Error(
      'REACT_APP_SHA512_KEY is not defined in the environment variables',
    );
  const timestamp = Date.now();
  const payload = `${timestamp}.${JSON.stringify(payloadData)}`;
  const jsStringLiteral = JSON.stringify(payload);
  console.log('payload :>> ', payload);
  console.log('jsStringLiteral :>> ', jsStringLiteral);
  const signature = await sha512(payload, conf.sha512SecretKey);
  console.log('signature in header :>> ', `${timestamp},${signature}`);

  return `${timestamp},${signature}`;
};
