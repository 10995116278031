import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Icon from '../Icon';

import styles from './Label.module.scss';

const Label = ({ id, children, className, wrapperClassName, label, icon, required }) => {
  const cnContainer = cn(styles.container, wrapperClassName);

  const cnLabel = cn(styles.label, className, {
    [styles.required]: required
  });

  return (
    <div className={cnContainer}>
      <label htmlFor={id} className={cnLabel}>
        {icon && <Icon label={icon} className={styles.icon} width={14} />}
        {label && label}
      </label>
      {children && React.cloneElement(children, { id })}
    </div>
  )
};

Label.displayName = 'Label';

Label.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
  label: PropTypes.string,
  icon: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string
};

Label.defaultProps = {
  children: null,
  icon: null,
  label: null,
  required: false,
  className: undefined
};

export default memo(Label);
