import { createAction } from 'redux-actions';
import Cookies from 'universal-cookie';
import { FullStory } from '@fullstory/browser';

import { setControlledLoader } from 'actions/ui';
import { getLocaleById } from 'locales';
import * as USER from 'constants/user';
import * as ENV from 'constants/env';
import { USER_ROLES } from 'constants/userRoles';
import currencies from 'config/currencies';
import { conf } from 'config/env';

import { fetchTeamsAccess } from 'slices/organization.slice';
import { checkPaymentApiStatus } from 'slices/campaignPayment.slice/campaignPayment.actions';

import { setClientToken } from './env';
import * as affilaeActions from './affilae';
import * as campaignsActions from './campaigns';
import * as profilesMyInfluencers from './myInfluencers';
import * as api from '../api/user';
import * as mapper from '../mappers/user';



export const userParamsLoading = createAction(USER.PARAMS_LOADING);
export const userParamsLoaded = createAction(USER.PARAMS_LOADED);
export const userParamsProfileLoaded = createAction(USER.PROFILE_PARAMS_LOADED);
export const userUpdate = createAction(USER.UPDATE);
export const userInfoUpdate = createAction(USER.INFO_UPDATE);
export const userEmailUpdate = createAction(USER.EMAIL_UPDATE);

export const userLicenceUpdate = createAction(USER.LICENCE_UPDATE);

export const setTags = createAction(USER.TAGS_SET);

export const sendingMethodAction = createAction(USER.SENDING_METHOD);
export const hideGmailConnectAction = createAction(USER.HIDE_GMAIL_CONNECT);
export const sendingUserPreferences = createAction(USER.SET_USER_UNACKOWNLEDGE_FEATURES);

export const saveIntegrations = createAction(USER.SAVE_INTEGRATIONS)
export const updateIntegrations = createAction(USER.UPDATE_INTEGRATIONS)

export const updateEnvKey = createAction(ENV.UPDATE_KEY);

export const clearInitialBannerMessage = createAction(USER.CLEAR_INITIAL_BANNER_MESSAGE);

export const getUserLicence = () => async (dispatch, getState) => {
  const {
    user: {
      profile: { admin },
    },
  } = getState();
  if (admin) return null;

  dispatch(setControlledLoader({ id: 'getUserLicence', show: true }));

  const licence = await api.getLicence();
  const paramsLicence = mapper.getUserLicence.fromApi(licence);

  dispatch(userLicenceUpdate({
		...paramsLicence,
		canExport: paramsLicence?.canExport
  }));

  dispatch(setControlledLoader({ id: 'getUserLicence', show: false }));

	return licence
};

export const getUserParams = () => async (dispatch) => {
	dispatch(userParamsLoading());

	const params = await api.appInit();

	if (!params) return false;

	const licence = await dispatch(getUserLicence());

	const paramsMapped = mapper.getUserParams.fromApi(params);
	const hasAffilaeConnection = mapper.checkIfHasAffilaeConnection.fromApi(params);
	const canExport = licence?.can_export || false;

	const {
		profile,
		isFromKolsquare,
		timezone: mappedTimezone,
		features: { manageCampaigns, tags: manageTags },
	} = paramsMapped;

	if (
		!isFromKolsquare ||
		/^(ludivine\.gibier|alix|alix\.dumarest|ore\.akinniranye|pierre-nicolas\.ngamby|vincent\.forest|fati\.cherkaoui)(.+)?@(kolsquare|brandandcelebrities)\.com$/.test(
			profile.email,
		)
	) {
		FullStory('start');
	} else {
		FullStory('shutdown');
	}

	let timezone = mappedTimezone;

	if (!timezone || timezone === 'null') {
		const { default: momentTz } = await import('moment-timezone');
		timezone = momentTz.tz.guess(false); // false: ignoreCache
		dispatch(
			updateAreaSettings({
				key: 'timezone',
				data: timezone,
			}),
		);
	}

	dispatch(userParamsLoaded({
		...paramsMapped,
		timezone,
		canExport: canExport || profile.admin,
	}));

	if ((!licence || !licence?.is_active) && !params?.current_user?.is_admin) {
		return true;
	}

	if (!licence?.name?.toLowerCase()?.includes('chrome')) {
		dispatch(checkPaymentApiStatus());

		if (manageTags) await dispatch(getTags());

		if (manageCampaigns && !profile.admin) {
			await Promise.all([
				dispatch(getUserProjects()),
				dispatch(profilesMyInfluencers.getCustomFields()),
				dispatch(getIntegrations()),
				dispatch(getCategoryEmails())
			])
		}

		if (!profile?.admin) {
			await dispatch(profilesMyInfluencers.getCustomList());
		}

		if ([USER_ROLES.SUPER_USER, USER_ROLES.MANAGER].includes(profile?.userRole)) {
			dispatch(fetchTeamsAccess());
		}

		if (hasAffilaeConnection) {
			dispatch(affilaeActions.setHasAffilaeConnection(hasAffilaeConnection));
		}
	}

	return true;
};

export const loadUserParamsUpdated = () => async (dispatch) => {
	const params = await api.appInit();
	if (params) {
		const { profile } = mapper.getUserParams.fromApi(params);
		dispatch(userParamsProfileLoaded(profile));
		await dispatch(getUserProjects());
		await dispatch(getUserLicence());
		if ([USER_ROLES.SUPER_USER, USER_ROLES.MANAGER].includes(profile?.userRole)) {
			dispatch(fetchTeamsAccess());
		}
	}
}

export const getUserProjects = status => async dispatch => {
	dispatch(setControlledLoader({ id: 'paneProfiles', show: true }));

	const res = await api.getProjects(status);

	const campaignsMapped = mapper.getProjects.fromApi(res.projects)

	dispatch(campaignsActions.batch(campaignsMapped))

	dispatch(setControlledLoader({ id: 'paneProfiles', show: false }));
};

export const logout = () => async (dispatch, getState) => {
	// clear session cookie
	document.cookie = `hideAnnouncement=; path=/`;
	new Cookies().remove(conf.cookieToken, {
		path: '/',
		domain: conf.cookieHostname,
		sameSite: true,
	});
	const { env : { locale }} = getState();
	const lang = getLocaleById(locale);
	await api.logout();
	dispatch(setClientToken(''));
	window.location.href = `${conf.urls.login}?lang=${lang?.iso2 || ''}`;
};

export const updateInfoRequest = (firstName, lastName, phone) => async dispatch => {
	const res = await api.updateInfo({ firstName, lastName, phone });

	if (res && res.error) return res;
	dispatch(userInfoUpdate({ firstName, lastName, phone }));
	return true;
};

export const updateEmailOnRequest = email => async dispatch => {
	const res = await api.updateEmail(email);
	if (res && res.error) return res;
	dispatch(userEmailUpdate(email));
	return true;
};

export const updatePasswordOnRequest = (password, newPassword, passwordConfirmation) => async dispatch => {
	const res = await api.updatePassword({password, newPassword, passwordConfirmation});

	if (res && res.error) return res;

	// Update token from cookies
	const cookies = new Cookies();
	const cookieToken = cookies.get(conf.cookieToken);
	dispatch(setClientToken(cookieToken));

	return true;
};

export const updateAreaSettings = ({ key, data }) => async (dispatch, getState) => {
	dispatch(setControlledLoader({ id: 'updateAreaSettings', show: true }));

  switch (key) {
    case 'currency': {
	  const { rates } = getState().env.currency;
      const res = await api.updateCurrency(data);
      if (!res || res.error) {
        console.error(
          `Could not update currency to ${data}. Reason: `,
          res?.error,
        );
      } else {
        const newCurrency = {
          value: data,
		  		rates,
          ...currencies.find(curr => curr.value === data),
        };
        dispatch(updateEnvKey({ key: 'currency', data: newCurrency }));
        dispatch(userUpdate({ key: 'currency', data: newCurrency }));
      }
      break;
    }
    case 'timezone':
      await api.updateTimezone(data);
      dispatch(updateEnvKey({ key, data }));
      break;
    case 'dateFormat':
      await api.updateDateFormat(data);
      dispatch(updateEnvKey({ key, data }));
      break;
    default:
      break;
  }
  dispatch(setControlledLoader({ id: 'updateAreaSettings', show: false }));
  return true;
};

/** *******
 *
 * TAGS / LABELS
 */
export const getTags = () => async dispatch => {
	try {
		const res = await api.getTags();
		const tags = mapper.getTags.fromApi(res);
		dispatch(setTags(tags));
		return tags;
	} catch (e) {
		console.error('[get tags]', e)
	}
};

// Add tag to user
export const addTag = ({ tag }) => async (dispatch, getState) => {
	const res = await api.addTag({ tag });
	if (res.error) return res;

	const addedId = mapper.addTag.fromApi(res);

	const { tags: currentTags } = getState().user;

	const newTag = {
		id: addedId,
		value: tag
	};

	const newTags = [
		newTag,
		...currentTags
	];

	dispatch(setTags(newTags));

	return newTag;
};

export const updateTag = ({ id, tag }) => async (dispatch, getState) => {
	const res = await api.updateTag({id, tag});
	if (res.error) return res;

	const { tags: currentTags } = getState().user;

	const newTags = currentTags.map(t => t.id === id ? ({
		...t,
		value: tag,
	}) : t);

	dispatch(setTags(newTags));

	return true;
};

export const deleteTags = ({ ids }) => async (dispatch, getState) => {
	const res = await api.deleteTags(ids);
	if (res.error) return res;

	const { tags: currentTags } = getState().user;

	const newTags = currentTags.filter(t => !ids.includes(t.id));

	dispatch(setTags(newTags));

	return true;
};

export const hideGmailConnect = displayGmailConnect => async dispatch => {
	await api.updateUserPreferences({ hideGmailConnect: displayGmailConnect });
	dispatch(hideGmailConnectAction(displayGmailConnect))
}

export const setSendingMethod = method => async (dispatch, getState) => {
	const { user: { sendingMethod } } = getState();

	if (method === sendingMethod) return null;

	await api.updateUserPreferences({ sendingMethod: method });
	dispatch(sendingMethodAction(method));
}

export const setUserPreferences = value => async (dispatch, getState) => {
	const { user: { unacknowledgedFeatures }} = getState();

	const data = unacknowledgedFeatures?.filter(item => item !== value);
	await api.updateUserPreferences({ unacknowledgedFeatures: data });
	dispatch(sendingUserPreferences(value));
}

export const getIntegrations = () => async dispatch => {
  const loadingId = 'getIntegrations';
  dispatch(setControlledLoader({ id: loadingId, show: true }));
  const integrations = await api.getIntegrations();
  if (integrations && !integrations.error) {
    dispatch(saveIntegrations(integrations));
  }
  dispatch(setControlledLoader({ id: loadingId, show: false }));
};

export const getCategoryEmails = () => async dispatch => {
	const category = await api.getCategoryEmails();
	if (!category || category.error) return null;
	dispatch(userUpdate({
		key: 'contactCategory',
		data: category,
	}));
}

export const acceptCgu = () => async (dispatch, getState) => {
	const { profile } = getState().user;

	await api.acceptCgu();

	dispatch(userUpdate({
		key: 'profile',
		data: {
			...profile,
			terms_of_use_acceptation_required: false
		}
	}));

	return true
}
