/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import isBefore from 'date-fns/isBefore';

import {
  createPaymentTask,
  updatePaymentTask,
  removePaymentTask,
  fetchPaymentTask,
  fetchPaymentContact,
  fetchSinglePaymentTask,
  fetchWallets,
  creditWallet,
  confirmPayment,
  fetchPayoutRecipient,
  fetchPayoutRecipientsBatch,
  updatePayoutRecipient,
  checkPaymentApiStatus,
  createPayoutValidation,
  confirmPayoutValidation,
  updatePaymentContact,
  getBillingAddress,
  createBillingAddress,
} from './campaignPayment.actions';
import { SliceState } from './campaignPayment.types';

import { startLoading, stopLoading } from '../utils';

const initialState: SliceState = {
  loading: [],

  isPreviewEnabled: false,
  isPaymentAPIConnected: false,
  _isPaymentAPIConnected: false,

  paymentContact: {
    id: null,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    is_phone_verified: false,
    salesforce_account_id: null,
  },
  globalBudget: {
    available: '',
    due: '',
    next: '',
    paid: '',
    project_currency: 'USD',
    total: '',
    total_fees: '',
    __paidAmounts: {
      due: 0,
      next: 0,
    },
  },
  billingAddress: {
    address_line_1: '',
    city: '',
    country: '',
    postal_code: '',
    company_name: '',
    id: null,
  },

  allPayouts: {},
  currentDisplayedPayoutIds: [],
  kolOverviewPayouts: [],
  paginationAndFilters: {
    page: 0,
    per_page: 10,
    total: 0,
    search: '',
    sort: '',
    hasMore: true,
    sortColumn: '',
    sortDirection: 'desc',
    campaign_id: null,
  },

  allWallets: [],
  paymentFeeRate: 0.05,

  payoutRecipients: {},
};

// Reducers
const campaignPaymentSlice = createSlice({
  name: 'campaignPayment',
  initialState,
  reducers: {
    updateKey: <K extends keyof SliceState>(
      state: SliceState,
      action: PayloadAction<{ key: K; data: SliceState[K] }>,
    ) => {
      state[action.payload.key] = action.payload.data;
    },
    updatePaginationAndFilters: (
      state,
      action: PayloadAction<Partial<SliceState['paginationAndFilters']>>,
    ) => {
      let { sort } = action.payload;
      if (!sort && action.payload.sortColumn && action.payload.sortDirection) {
        sort = `${action.payload.sortColumn}-${action.payload.sortDirection}`;
      }
      state.paginationAndFilters = {
        ...state.paginationAndFilters,
        ...action.payload,
        sort: sort || state.paginationAndFilters.sort,
      };
    },
    resetPaginationAndFilters: (state) => {
      state.paginationAndFilters = {
        ...initialState.paginationAndFilters,
        payout_filters: state.paginationAndFilters.payout_filters,
      };
    },
    setIsPaymentPreviewEnabled: (state, action: PayloadAction<boolean>) => {
      state.isPreviewEnabled = action.payload;
      state.isPaymentAPIConnected =
        state._isPaymentAPIConnected || action.payload;
    },

    saveWallet: (
      state,
      action: PayloadAction<SliceState['allWallets'][number]>,
    ) => {
      if (action.payload) {
        const existingWalletId = state.allWallets.findIndex(
          (wallet) => wallet.id === action.payload.id,
        );
        if (existingWalletId > -1) {
          state.allWallets[existingWalletId] = {
            ...state.allWallets[existingWalletId],
            ...action.payload,
          };
        } else {
          state.allWallets.push(action.payload);
        }
      }
    },
  },
  extraReducers: (builder) => {
    // fetchPaymentContact
    builder
      .addCase(fetchPaymentContact.pending, (state) => {
        startLoading(state, fetchPaymentContact);
      })
      .addCase(fetchPaymentContact.fulfilled, (state, action) => {
        if (action.payload) {
          state.paymentContact = action.payload.payment_contact;
        }
        stopLoading(state, fetchPaymentContact);
      })
      .addCase(fetchPaymentContact.rejected, (state) => {
        stopLoading(state, fetchPaymentContact);
      });
    // updatePaymentContact
    builder
      .addCase(updatePaymentContact.pending, (state) => {
        startLoading(state, updatePaymentContact);
      })
      .addCase(updatePaymentContact.fulfilled, (state, action) => {
        if (action.payload) {
          state.paymentContact = action.payload.payment_contact;
        }
        stopLoading(state, updatePaymentContact);
      })
      .addCase(updatePaymentContact.rejected, (state) => {
        stopLoading(state, updatePaymentContact);
      });
    // fetchPaymentTask
    builder
      .addCase(fetchPaymentTask.pending, (state) => {
        startLoading(state, fetchPaymentTask);
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .addCase(fetchPaymentTask.fulfilled, (state, action) => {
        // Update global budget
        if (action.payload.response.global_budget) {
          state.globalBudget = {
            ...state.globalBudget,
            ...action.payload.response.global_budget,
          };
          state.globalBudget.due = (
            Number(state.globalBudget.due) -
            (state.globalBudget.__paidAmounts?.due || 0)
          ).toString();
          state.globalBudget.next = (
            Number(state.globalBudget.next) -
            (state.globalBudget.__paidAmounts?.next || 0)
          ).toString();
          state.globalBudget.paid = (
            Number(state.globalBudget.paid) +
            (state.globalBudget.__paidAmounts?.due || 0) +
            (state.globalBudget.__paidAmounts?.next || 0)
          ).toString();
        }

        // Update table view
        const { payout_filters, page } = action.payload.params;

        state.paginationAndFilters = {
          ...state.paginationAndFilters,
          total: action.payload.response.total_count,
          hasMore:
            action.payload.response.page + 1 <
            Math.ceil(
              action.payload.response.total_count /
                action.payload.response.per_page,
            ),
          page: action.payload.response.page,
        };

        if (payout_filters === 'overview') {
          state.kolOverviewPayouts =
            page === 0
              ? action.payload.response.payouts
              : [
                  ...state.kolOverviewPayouts,
                  ...action.payload.response.payouts,
                ];
        } else {
          const locallyFakedPayouts = Object.values(state.allPayouts)
            .filter((payout) => {
              if (!payout.__fake) return false;
              if (payout_filters === 'due') {
                return (
                  payout.status === 'scheduled' &&
                  isBefore(new Date(payout.due_date), new Date())
                );
              }
              if (payout_filters === 'next') {
                return (
                  payout.status === 'scheduled' &&
                  isBefore(new Date(payout.due_date), new Date())
                );
              }
              return true;
            })
            .map((payout) => payout.id);

          state.allPayouts = action.payload.response.payouts.reduce(
            (payouts, payout) => {
              if (!payout.id) return payouts;
              if (!state.allPayouts[payout.id]) {
                return { ...payouts, [payout.id]: payout };
              }

              // If payout exists, and payout has been faked locally, ignore it
              if (state.allPayouts[payout.id].__fake) return payouts;

              return { ...payouts, [payout.id]: payout };
            },
            state.allPayouts,
          );

          const newPayoutIds = action.payload.response.payouts.reduce(
            (ids, payout) => {
              if (!payout.id) return ids;
              if (state.allPayouts[payout.id]?.__fake) return ids;
              return [...ids, payout.id];
            },
            locallyFakedPayouts,
          );

          if (payout_filters) {
            state.currentDisplayedPayoutIds = [
              ...new Set(
                page === 0
                  ? newPayoutIds
                  : [...state.currentDisplayedPayoutIds, ...newPayoutIds],
              ),
            ];
          }
        }
        stopLoading(state, fetchPaymentTask);
      })
      .addCase(fetchPaymentTask.rejected, (state) => {
        stopLoading(state, fetchPaymentTask);
      });

    // createPaymentTask
    builder
      .addCase(createPaymentTask.pending, (state) => {
        startLoading(state, createPaymentTask);
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .addCase(createPaymentTask.fulfilled, (state, action) => {
        // if (action.payload) {
        //   state.createPaymentTask = action.payload;
        // }
        stopLoading(state, createPaymentTask);
      })
      .addCase(createPaymentTask.rejected, (state) => {
        stopLoading(state, createPaymentTask);
      });

    // updatePaymentTask
    builder
      .addCase(updatePaymentTask.pending, (state) => {
        startLoading(state, updatePaymentTask);
      })
      .addCase(updatePaymentTask.fulfilled, (state, action) => {
        if (action.payload.id) {
          const payoutToUpdate = state.allPayouts[action.payload.id];
          if (!payoutToUpdate) return;
          payoutToUpdate.amount = action.payload.amount;
          payoutToUpdate.due_date = action.payload.due_date;
          // payoutToUpdate.currency = action.payload.currency;

          state.allPayouts[action.payload.id] = payoutToUpdate;
        }
        stopLoading(state, updatePaymentTask);
      })
      .addCase(updatePaymentTask.rejected, (state) => {
        stopLoading(state, updatePaymentTask);
      });

    // fetchSinglePaymentTask
    builder
      .addCase(fetchSinglePaymentTask.pending, (state) => {
        startLoading(state, fetchSinglePaymentTask);
      })
      .addCase(fetchSinglePaymentTask.fulfilled, (state, action) => {
        const { id } = action.payload;
        state.allPayouts[id] = {
          ...(state.allPayouts[id] || {}),
          ...action.payload,
        };
        stopLoading(state, fetchSinglePaymentTask);
      })
      .addCase(fetchSinglePaymentTask.rejected, (state) => {
        stopLoading(state, fetchSinglePaymentTask);
      });

    // removePaymentTask
    builder
      .addCase(removePaymentTask.pending, (state) => {
        startLoading(state, removePaymentTask);
      })
      .addCase(removePaymentTask.fulfilled, (state, action) => {
        if (action.payload.params.id) {
          delete state.allPayouts[action.payload.params.id];
          state.currentDisplayedPayoutIds =
            state.currentDisplayedPayoutIds.filter(
              (id) => id !== action.payload.params.id,
            );
        }
        stopLoading(state, removePaymentTask);
      })
      .addCase(removePaymentTask.rejected, (state) => {
        stopLoading(state, removePaymentTask);
      });

    builder
      .addCase(fetchWallets.pending, (state) => {
        startLoading(state, fetchWallets);
      })
      .addCase(fetchWallets.fulfilled, (state, action) => {
        if (action.payload) {
          state.allWallets = action.payload.wallets;
        }
        stopLoading(state, fetchWallets);
      })
      .addCase(fetchWallets.rejected, (state) => {
        stopLoading(state, fetchWallets);
      });

    builder
      .addCase(creditWallet.pending, (state) => {
        startLoading(state, creditWallet);
      })
      .addCase(creditWallet.fulfilled, (state) => {
        stopLoading(state, creditWallet);
      })
      .addCase(creditWallet.rejected, (state) => {
        stopLoading(state, creditWallet);
      });

    builder
      .addCase(confirmPayment.pending, (state) => {
        startLoading(state, confirmPayment);
      })
      .addCase(confirmPayment.fulfilled, (state, action) => {
        const __paidAmounts = action.payload.payouts.reduce((acc, payout) => {
          return {
            due:
              payout.__previous_task_status === 'due'
                ? (acc?.due || 0) + Number(payout.__amount_paid || 0)
                : 0,
            next:
              payout.__previous_task_status === 'next'
                ? (acc?.next || 0) + Number(payout.__amount_paid || 0)
                : 0,
          };
        }, state.globalBudget.__paidAmounts);

        state.globalBudget.__paidAmounts = __paidAmounts;
        const payouts = { ...state.allPayouts };
        action.payload.payouts.forEach((payout) => {
          payouts[payout.id] = payout;
        });
        state.allPayouts = payouts;
        action.payload.wallets.forEach((wallet) => {
          const index = state.allWallets.findIndex(
            (_wallet) => _wallet.id === wallet.id,
          );
          if (index > -1) {
            state.allWallets[index] = wallet;
          }
        });

        // if in currently displayed due/next list, remove it
        const {
          paginationAndFilters: { payout_filters },
          currentDisplayedPayoutIds,
        } = state;
        const payoutIds = action.payload.payouts.map(({ id }) => id);
        if (payout_filters === 'due' || payout_filters === 'next') {
          state.currentDisplayedPayoutIds = currentDisplayedPayoutIds.filter(
            (id) => !payoutIds.includes(id),
          );
        }
        stopLoading(state, confirmPayment);
      })
      .addCase(confirmPayment.rejected, (state) => {
        stopLoading(state, confirmPayment);
      });

    // fetchPayoutRecipient
    builder
      .addCase(fetchPayoutRecipient.pending, (state) => {
        startLoading(state, fetchPayoutRecipient);
      })
      .addCase(fetchPayoutRecipient.fulfilled, (state, action) => {
        if (action.payload) {
          state.payoutRecipients[action.payload.profileId] =
            action.payload.payout_recipient;
        }
        stopLoading(state, fetchPayoutRecipient);
      })
      .addCase(fetchPayoutRecipient.rejected, (state) => {
        stopLoading(state, fetchPayoutRecipient);
      });

    builder
      .addCase(fetchPayoutRecipientsBatch.pending, (state) => {
        startLoading(state, fetchPayoutRecipient);
      })
      .addCase(fetchPayoutRecipientsBatch.fulfilled, (state, action) => {
        if (action.payload) {
          action.payload.payout_recipients.forEach((payoutRecipient) => {
            state.payoutRecipients[payoutRecipient.public_profile_id] =
              payoutRecipient;
          });
        }
        stopLoading(state, fetchPayoutRecipientsBatch);
      })
      .addCase(fetchPayoutRecipientsBatch.rejected, (state) => {
        stopLoading(state, fetchPayoutRecipientsBatch);
      });

    // updatePayoutRecipient
    builder
      .addCase(updatePayoutRecipient.pending, (state) => {
        startLoading(state, updatePayoutRecipient);
      })
      .addCase(updatePayoutRecipient.fulfilled, (state, action) => {
        if (action.payload) {
          state.payoutRecipients[action.payload.profileId] =
            action.payload.payout_recipient;
        }
        stopLoading(state, updatePayoutRecipient);
      })
      .addCase(updatePayoutRecipient.rejected, (state) => {
        stopLoading(state, updatePayoutRecipient);
      });

    // checkPaymentApiStatus
    builder
      .addCase(checkPaymentApiStatus.pending, (state) => {
        startLoading(state, checkPaymentApiStatus);
      })
      .addCase(checkPaymentApiStatus.fulfilled, (state, action) => {
        state.isPaymentAPIConnected = action.payload.payment_with_api_enabled;
        state._isPaymentAPIConnected = action.payload.payment_with_api_enabled;
        stopLoading(state, checkPaymentApiStatus);
      })
      .addCase(checkPaymentApiStatus.rejected, (state) => {
        stopLoading(state, checkPaymentApiStatus);
      });

    // createPayoutValidation
    builder
      .addCase(createPayoutValidation.pending, (state) => {
        startLoading(state, createPayoutValidation);
      })
      .addCase(createPayoutValidation.fulfilled, (state) => {
        stopLoading(state, createPayoutValidation);
      })
      .addCase(createPayoutValidation.rejected, (state) => {
        stopLoading(state, createPayoutValidation);
      });

    // confirmPayoutValidation
    builder
      .addCase(confirmPayoutValidation.pending, (state) => {
        startLoading(state, confirmPayoutValidation);
      })
      .addCase(confirmPayoutValidation.fulfilled, (state) => {
        stopLoading(state, confirmPayoutValidation);
      })
      .addCase(confirmPayoutValidation.rejected, (state) => {
        stopLoading(state, confirmPayoutValidation);
      });

    // getBillingAddress
    builder
      .addCase(getBillingAddress.pending, (state) => {
        startLoading(state, getBillingAddress);
      })
      .addCase(getBillingAddress.fulfilled, (state, action) => {
        state.billingAddress = action.payload;
        stopLoading(state, getBillingAddress);
      })
      .addCase(getBillingAddress.rejected, (state) => {
        stopLoading(state, getBillingAddress);
      });

    // createBillingAddress
    builder
      .addCase(createBillingAddress.pending, (state) => {
        startLoading(state, createBillingAddress);
      })
      .addCase(createBillingAddress.fulfilled, (state) => {
        stopLoading(state, createBillingAddress);
      })
      .addCase(createBillingAddress.rejected, (state) => {
        stopLoading(state, createBillingAddress);
      });
  },
});

// action creators
export const { actions } = campaignPaymentSlice;

export default campaignPaymentSlice.reducer;
