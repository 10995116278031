import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'config/store';
import { format } from 'date-fns';

import { getCampaignDetails } from 'actions/campaigns';
import { FNS_BACKEND_DATE_FORMAT } from 'config/app';

// import toast from 'utils/toast';
// import { getIntl } from 'utils/HOCs/IntlGlobalSingleton';

import * as api from './campaignPayment.api';
import {
  Wallet,
  Payout,
  PaymentContact,
  FetchPaymentTaskParams,
  FetchPaymentTaskResponse,
  CreatePaymentTaskParams,
  UpdatePaymentTaskParams,
  BatchUpdateStatusParams,
  CreatePaymentTaskResponse,
  VerifyPaymentTaskParams,
  VerifyPaymentTaskResponse,
  UpdatePaymentTaskResponse,
  CreditWalletParams,
  FetchPayoutRecipientParams,
  UpdatePayoutRecipientParams,
  UpdatePayoutRecipientResponse,
  FetchPayoutRecipientResponse,
  CreditWalletResponse,
  FetchWalletsReponse,
  CheckPaymentApiStatusResponse,
  GetPayoutsInformationsParams,
  VerifyPayoutValidationParams,
  VerifyPayoutValidationResponse,
  CreatePayoutValidationpParams,
  CreatePayoutValidationpResponse,
  GetPayoutsInformationResponse,
  FetchPayoutRecipientsBatchResponse,
  FetchPayoutRecipientsBatchParams,
  BillingAddress,
} from './campaignPayment.types';
import { saveWallet } from '.';

// Payment Contact
export const fetchPaymentContact = createAsyncThunk<
  { payment_contact: PaymentContact },
  void,
  { state: RootState }
>('payment/fetchPaymentContact', async () => {
  const response = await api.fetchPaymentContact();
  if (response && !response.error) {
    return response;
  }
  return Promise.reject(response.error);
});

export const updatePaymentContact = createAsyncThunk<
  { payment_contact: PaymentContact },
  Pick<PaymentContact, 'first_name' | 'last_name' | 'email' | 'phone'>,
  { state: RootState }
>('payment/updatePaymentContact', async (paymentContact) => {
  const response = await api.updatePaymentContact(paymentContact);
  if (response && !response.error) {
    return response;
  }
  return Promise.reject(response.error);
});

export const validatePhoneNumber = createAsyncThunk<
  void,
  { phoneNumber: string },
  { state: RootState }
>('payment/validatePhoneNumber', async ({ phoneNumber }) => {
  const response = await api.validatePhoneNumber(phoneNumber);
  if (response && !response.error) {
    return response;
  }
  return Promise.reject(response.error);
});

export const verifyValidationCode = createAsyncThunk<
  VerifyPaymentTaskResponse,
  { verificationCode: string },
  { state: RootState }
>('payment/verificationCode', async ({ verificationCode }) => {
  const response = await api.verifyValidationCode(verificationCode);
  if (response && !response.error) {
    return response;
  }
  return Promise.reject(response.error);
});

// Payment Task
export const fetchPaymentTask = createAsyncThunk<
  FetchPaymentTaskResponse,
  FetchPaymentTaskParams | undefined,
  { state: RootState }
>('payment/fetchPaymentTask', async (params, { getState }) => {
  let _params: FetchPaymentTaskParams;
  if (!params) {
    // `sortColumn` and `sortDirection` are not transformed to `sort` param here
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { hasMore, sortColumn, sortDirection, total, ...filters } =
      getState().campaignPayment.paginationAndFilters;
    const campaign_id = getState().views.campaignDetails.id;
    if (!campaign_id) return Promise.reject(new Error('No campaign ID'));
    _params = {
      scopes: 'profiles,users,global_budget',
      ...filters,
      campaign_id: filters.campaign_id || campaign_id,
    };
  } else {
    _params = {
      ...params,
      scopes: params.scopes || 'profiles,users,global_budget',
    };
  }

  const response = await api.fetchPaymentTask(_params);
  if (response && !response.error) {
    return { response, params: _params };
  }
  return Promise.reject(response.error);
});

export const createPaymentTask = createAsyncThunk<
  CreatePaymentTaskResponse,
  CreatePaymentTaskParams,
  { state: RootState }
>('payment/createPaymentTask', async (params, { dispatch }) => {
  const response = await api.createPaymentTask({
    amount: Number(params.amount),
    campaign_id: Number(params.campaign_id),
    currency: params.currency,
    due_date: format(new Date(params.due_date), FNS_BACKEND_DATE_FORMAT),
    public_profile_ids: params.public_profile_ids,
  });
  if (response && !response.error) {
    void dispatch(getCampaignDetails(params.campaign_id));
    return response as CreatePaymentTaskResponse;
  }
  return Promise.reject(response.error);
});

export const updatePaymentTask = createAsyncThunk<
  UpdatePaymentTaskResponse,
  UpdatePaymentTaskParams,
  { state: RootState }
>('payment/updatePaymentTask', async (params, { dispatch }) => {
  const dateToUpdate = Object.assign(
    {},
    {
      id: params.id,
      campaign_id: Number(params.campaign_id),
      scopes: 'profiles,users',
    },
    params.amount && { amount: Number(params.amount) },
    params.currency && { currency: params.currency },
    params.due_date && {
      due_date: format(new Date(params.due_date), FNS_BACKEND_DATE_FORMAT),
    },
  );

  const response = await api.updatePaymentTask(dateToUpdate);
  if (response && !response.error) {
    void dispatch(getCampaignDetails(params.campaign_id));
    return response;
  }
  return Promise.reject(response.error);
});

export const verifyPaymentTask = createAsyncThunk<
  VerifyPaymentTaskResponse,
  VerifyPaymentTaskParams,
  { state: RootState }
>('payment/verifyPaymentTask', async (params) => {
  const response = await api.fetchAllowedCurrencies({
    campaign_id: Number(params.campaign_id),
    public_profile_ids: params.public_profile_ids,
  });

  if (response && !response.error) {
    return response as VerifyPaymentTaskResponse;
  }
  return Promise.reject(response.error);
});

export const batchUpdateStatusPaymentTask = createAsyncThunk<
  { response: any; params: BatchUpdateStatusParams },
  BatchUpdateStatusParams,
  { state: RootState }
>('payment/batchUpdateStatusPaymentTask', async (params, { dispatch }) => {
  const response = await api.batchUpdateStatus({
    ids: params.ids,
    campaign_id: Number(params.campaign_id),
    status: params.status,
  });
  if (response && !response.error) {
    void dispatch(getCampaignDetails(params.campaign_id));
    void dispatch(fetchPaymentTask());
    return { response, params };
  }
  return Promise.reject(response.error);
});

export const fetchSinglePaymentTask = createAsyncThunk<
  UpdatePaymentTaskResponse,
  { id: number; campaignId: number },
  { state: RootState }
>('payment/fetchSinglePaymentTask', async (params) => {
  const response = await api.fetchSinglePaymentTask({
    id: Number(params.id),
    campaign_id: Number(params.campaignId),
    scopes: 'profiles,users',
  });
  if (response && !response.error) {
    return response;
  }
  return Promise.reject(response.error);
});

export const removePaymentTask = createAsyncThunk<
  { params: { id: number; campaignId: number } },
  { id: number; campaignId: number },
  { state: RootState }
>('payment/removePaymentTask', async (params, { dispatch }) => {
  const response = await api.removePaymentTask({
    id: Number(params.id),
    campaign_id: Number(params.campaignId),
  });
  if (response && !response.error) {
    void dispatch(getCampaignDetails(params.campaignId));
    return { params };
  }
  return Promise.reject(response.error);
});

export const cancelPaymentTask = createAsyncThunk<
  void,
  { id: number; campaignId: number },
  { state: RootState }
>('payment/cancelPaymentTask', async (params, { dispatch }) => {
  await dispatch(
    batchUpdateStatusPaymentTask({
      campaign_id: params.campaignId,
      ids: [params.id],
      status: 'canceled',
    }),
  );
});

// Wallets
export const fetchWallets = createAsyncThunk<
  FetchWalletsReponse,
  void,
  { state: RootState }
>('payment/fetchWallets', async (_payoutparams, { getState }) => {
  if (getState().campaignPayment.isPreviewEnabled) {
    const currentWallets = getState().campaignPayment.allWallets;
    return new Promise((resolve) => {
      setTimeout(() => resolve({ wallets: currentWallets }), 2000);
    });
  }
  // Real API call
  const response = await api.fetchWallets();
  if (response && !response.error) {
    return response;
  }
  return Promise.reject(response.error);
});

export const creditWallet = createAsyncThunk<
  CreditWalletResponse,
  CreditWalletParams,
  { state: RootState }
>('payment/creditWallet', async (params, { getState, dispatch }) => {
  if (getState().campaignPayment.isPreviewEnabled) {
    const { allWallets } = getState().campaignPayment;
    const walletForThisCurrency = allWallets.find(
      (wallet) => wallet.currency === params.currency,
    ) || {
      id: new Date().getTime(),
      balance: '0',
      currency: params.currency,
      upcomingExpenses: 0,
      po_number: params.poNumber,
      provider: null,
      salesforce_account_id: null,
    };

    return new Promise((resolve) => {
      setTimeout(() => {
        dispatch(
          saveWallet({
            ...walletForThisCurrency,
            balance: (
              Number(walletForThisCurrency.balance) + Number(params.amount)
            ).toString(),
          }),
        );
        return resolve({
          wallet_credit_request: {
            amount: params.amount,
            description: params.description,
            id: new Date().getTime(),
            wallet_id: walletForThisCurrency
              ? walletForThisCurrency.id
              : new Date().getTime(),
          },
        });
      }, 2000);
    });
  }
  // Real API here
  const response = await api.creditWallet(params);
  if (response && !response.error) {
    return response;
  }
  return Promise.reject(response.error);
});

export const confirmPayment = createAsyncThunk<
  VerifyPayoutValidationResponse,
  VerifyPayoutValidationParams,
  { state: RootState }
>('payment/confirmPayment', async (params, { getState }) => {
  if (getState().campaignPayment.isPreviewEnabled) {
    const state = getState();
    const { email, firstName, lastName, id } = state.user.profile;
    const { allPayouts, allWallets, paymentFeeRate } = state.campaignPayment;

    // Assume validation has been done
    // Get payment amount by currency
    const payoutsToPay: Array<Payout> = params.payout_ids.map(
      (id) => allPayouts[id],
    );

    const walletByCurrency: Partial<Record<Wallet['currency'], Wallet>> =
      allWallets.reduce((map, wallet) => {
        return { ...map, [wallet.currency]: { ...wallet } };
      }, {});

    const updatedPayouts: Array<Payout> = [];

    payoutsToPay.forEach((payout) => {
      const amountToPay = Number(payout.amount_to_pay) * (1 + paymentFeeRate);

      // remove it from wallet
      if (walletByCurrency[payout.currency]) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        walletByCurrency[payout.currency]!.balance = (
          Number(walletByCurrency[payout.currency]?.balance || 0) - amountToPay
        ).toString();
      }

      // Mark payout as paid
      const _payout = { ...payout };
      _payout.amount_paid = (
        Number(_payout.amount_paid) + Number(payout.amount_to_pay)
      ).toString();
      _payout.amount_to_pay = (
        Number(_payout.amount_to_pay) - Number(payout.amount_to_pay)
      ).toString();
      _payout.paid_at = new Date().toISOString();
      _payout.paid_by = {
        email: email || '',
        greeting_name: `${firstName} ${lastName}`,
        id: Number(id || '1'),
      };
      _payout.task_status = 'paid';
      _payout.status = 'paid';

      // Flag payout as from the fake flow, prevents API from updating it
      _payout.__fake = true;
      _payout.__amount_paid = Number(payout.amount_to_pay);
      _payout.__previous_task_status = payout.task_status;

      updatedPayouts.push(_payout);
    });

    return new Promise((resolve) => {
      setTimeout(
        () =>
          resolve({
            payouts: updatedPayouts,
            wallets: Object.values(walletByCurrency),
          }),
        2000,
      );
    });
  }

  // Real API here
  const response = await api.verifyPayoutValidation(params);
  if (response && !response.error) {
    return response;
  }
});

export const cleanFakeTasksAndWallets = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('payment/cleanFakeTasksAndWallets', async (params, { dispatch }) => {
  await dispatch(fetchPaymentTask());
  await dispatch(fetchWallets());
});

// Payment recipients
export const fetchPayoutRecipient = createAsyncThunk<
  FetchPayoutRecipientResponse & { profileId: number },
  FetchPayoutRecipientParams,
  { state: RootState }
>('payment/fetchPayoutRecipient', async (params) => {
  const response = await api.fetchPayoutRecipient(params);

  if (response && !response.error) {
    return { ...response, profileId: params.profileId };
  }
  return Promise.reject(response.error);
});

export const fetchPayoutRecipientsBatch = createAsyncThunk<
  FetchPayoutRecipientsBatchResponse,
  FetchPayoutRecipientsBatchParams,
  { state: RootState }
>('payment/fetchPayoutRecipientsBatch', async (params) => {
  const response = await api.fetchPayoutRecipientsBatch(params);

  if (response && !response.error) {
    return response;
  }
  return Promise.reject(response.error);
});

export const updatePayoutRecipient = createAsyncThunk<
  UpdatePayoutRecipientResponse & { profileId: number },
  UpdatePayoutRecipientParams,
  { state: RootState }
>('payment/updatePayoutRecipient', async (params) => {
  const response = await api.updatePayoutRecipient(params);

  if (response && !response.error) {
    return { ...response, profileId: params.profileId };
  }
  return Promise.reject(response.error);
});

// Check Payment API Status
export const checkPaymentApiStatus = createAsyncThunk<
  CheckPaymentApiStatusResponse,
  void,
  { state: RootState }
>('payment/checkPaymentApiStatus', async () => {
  const response = await api.checkPaymentApiStatus();
  if (response && !response.error) {
    return response;
  }
  return Promise.reject(response.error);
});

// Payout Validation process
export const getPayoutsInformation = createAsyncThunk<
  GetPayoutsInformationResponse,
  GetPayoutsInformationsParams,
  { state: RootState }
>('payment/getPayoutsValidation', async (params) => {
  const response = await api.getPayoutsInformations(params);
  if (response && !response.error) {
    return response;
  }
  return Promise.reject(response.error);
});

export const createPayoutValidation = createAsyncThunk<
  CreatePayoutValidationpResponse,
  CreatePayoutValidationpParams,
  { state: RootState }
>('payment/createPayoutValidation', async (params) => {
  const response = await api.createPayoutValidation(params);
  if (response && !response.error) {
    return response;
  }
  return Promise.reject(response.error);
});

export const confirmPayoutValidation = createAsyncThunk<
  VerifyPayoutValidationResponse,
  VerifyPayoutValidationParams,
  { state: RootState }
>('payment/confirmPayoutValidation', async (params, { dispatch }) => {
  const response = await api.verifyPayoutValidation(params);
  if (response && !response.error) {
    void dispatch(getCampaignDetails(params.campaign_id));
    void dispatch(fetchPaymentTask());
    return response;
  }
  return Promise.reject(response.error);
});

// getBillingAddress
export const getBillingAddress = createAsyncThunk<
  BillingAddress,
  void,
  { state: RootState }
>('payment/getBillingAddress', async () => {
  const response = await api.getBillingAddress();
  if (response && !response.error) {
    return response;
  }
  return Promise.reject(response.error);
});

// createBillingAddress
export const createBillingAddress = createAsyncThunk<
  void,
  Omit<BillingAddress, 'id'>,
  { state: RootState }
>('payment/createBillingAddress', async (billingInfos) => {
  const response = await api.createBillingAddress(billingInfos);
  if (response && !response.error) {
    return;
  }
  return Promise.reject(response.error);
});
