import React, { ComponentProps, forwardRef, useCallback } from 'react';
import { Input2, Text } from 'kolkit';
import { FormattedMessage } from 'react-intl';

import styles from './InputPhoneWithCountrySelector.module.scss';

const InputComponent = forwardRef<typeof Input2, ComponentProps<typeof Input2>>(
  (
    { labelId = 'auth.doubleAuthentication.modal.setup.country', ...props },
    ref,
  ) => {
    const handleReset = useCallback(() => props.onChange(''), [props]);

    return (
      <>
        {labelId && (
          <Text resetMargin className={styles.numberLabel}>
            <FormattedMessage
              id="auth.doubleAuthentication.modal.setup.number"
              defaultMessage="Enter phone number"
            />
          </Text>
        )}
        <Input2
          {...props}
          ref={ref}
          fullWidth
          error={props.error}
          errorMessage={props.errorMessage}
          onClickIcon={handleReset}
          className={styles.numberInput}
        />
      </>
    );
  },
);

InputComponent.displayName = 'InputComponent';

export default InputComponent;
