const hmacSha512: (str: string, secretKey: string) => Promise<string> = (
  str,
  secretKey,
) => {
  // encoder to convert string to Uint8Array
  const enc = new TextEncoder();

  try {
    return crypto.subtle
      .importKey(
        'raw', // raw format of the key - should be Uint8Array
        enc.encode(secretKey),
        {
          // algorithm details
          name: 'HMAC',
          hash: { name: 'SHA-512' },
        },
        false, // export = false
        ['sign', 'verify'], // what this key can do
      )
      .then((key) =>
        crypto.subtle.sign('HMAC', key, enc.encode(str)).then((signature) => {
          return Array.prototype.map
            .call(new Uint8Array(signature), (x) =>
              x.toString(16).padStart(2, '0'),
            )
            .join('');
        }),
      );
  } catch (error) {
    throw new Error(`HMAC error: ${error}`);
  }
};

export default hmacSha512;
