import { get, put, post, remove } from 'api';
import { ApiPromise } from 'api/types';

import type {
  PaymentContact,
  CreatePaymentTaskParams,
  CreatePaymentTaskResponse,
  UpdatePaymentTaskParams,
  BatchUpdateStatusParams,
  FetchPaymentTaskParams,
  VerifyPaymentTaskParams,
  VerifyPaymentTaskResponse,
  FetchSinglePaymentTaskParams,
  FetchPaymentTaskResponse,
  FetchSinglePaymentTaskResponse,
  UpdatePaymentTaskResponse,
  FetchPayoutRecipientParams,
  FetchPayoutRecipientResponse,
  UpdatePayoutRecipientParams,
  UpdatePayoutRecipientResponse,
  CreditWalletParams,
  CreditWalletResponse,
  FetchWalletsReponse,
  CheckPaymentApiStatusResponse,
  VerifyPayoutValidationParams,
  CreatePayoutValidationpParams,
  GetPayoutsInformationsParams,
  FetchPayoutRecipientsBatchParams,
  FetchPayoutRecipientsBatchResponse,
  GetPayoutsInformationResponse,
  BillingAddress,
} from './campaignPayment.types';
import { getValidationHash } from './campaignPayment.utils';

// Payment contact
export const fetchPaymentContact: () => ApiPromise<{
  payment_contact: PaymentContact;
}> = () =>
  get({
    endpoint: `influence/v2/payment_contact`,
    catchErrors: [404],
  });

export const updatePaymentContact: (
  contact: Pick<PaymentContact, 'first_name' | 'last_name' | 'email' | 'phone'>,
) => ApiPromise<{
  payment_contact: PaymentContact;
}> = (contact) =>
  put({
    endpoint: `influence/v2/payment_contact`,
    params: contact,
    catchErrors: [422],
  });

// Phone verification
export const validatePhoneNumber: (phoneNumber: string) => ApiPromise<any> = (
  phoneNumber,
) =>
  put({
    endpoint: `influence/v2/payment_contact/phone_validation`,
    params: {
      phone: phoneNumber,
    },
    catchErrors: [422],
  });

export const verifyValidationCode: (
  verificationCode: string,
) => ApiPromise<VerifyPaymentTaskResponse> = (verificationCode) =>
  post({
    endpoint: `influence/v2/payment_contact/phone_validation/verify`,
    params: {
      code: verificationCode,
    },
    catchErrors: [422],
  });

// Payment task
export const fetchPaymentTask: (
  params: FetchPaymentTaskParams,
) => ApiPromise<FetchPaymentTaskResponse['response']> = ({
  campaign_id,
  ...params
}) =>
  get({
    endpoint: `influence/v2/projects/${campaign_id}/payouts`,
    params,
    catchErrors: [404, 400],
  });

export const createPaymentTask: (
  params: CreatePaymentTaskParams,
) => ApiPromise<CreatePaymentTaskResponse> = (params) => {
  const { campaign_id, ...apiParams } = params;
  return post({
    endpoint: `influence/v2/projects/${campaign_id}/payouts/batch_create`,
    params: apiParams,
    catchErrors: [422],
  });
};

export const fetchSinglePaymentTask: (
  params: FetchSinglePaymentTaskParams,
) => ApiPromise<FetchSinglePaymentTaskResponse> = (params) => {
  const { campaign_id, id, scopes } = params;
  return get({
    endpoint: `influence/v2/projects/${campaign_id}/payouts/${id}`,
    params: { scopes },
    catchErrors: [422],
  });
};

export const updatePaymentTask: (
  params: UpdatePaymentTaskParams,
) => ApiPromise<UpdatePaymentTaskResponse> = (params) => {
  const { campaign_id, id, ...apiParams } = params;
  return put({
    endpoint: `influence/v2/projects/${campaign_id}/payouts/${id}`,
    params: apiParams,
    catchErrors: [422],
  });
};

export const batchUpdateStatus: (
  params: BatchUpdateStatusParams,
) => ApiPromise<any> = (params) => {
  const { campaign_id, ...apiParams } = params;
  return put({
    endpoint: `influence/v2/projects/${campaign_id}/payouts/batch_update`,
    params: apiParams,
    catchErrors: [422],
  });
};

export const removePaymentTask: (params: {
  id: number;
  campaign_id;
}) => ApiPromise<any> = (params) => {
  const { id, campaign_id } = params;
  return remove({
    endpoint: `influence/v2/projects/${campaign_id}/payouts/${id}`,
    catchErrors: [404],
  });
};

export const fetchAllowedCurrencies: (
  params: VerifyPaymentTaskParams,
) => ApiPromise<VerifyPaymentTaskResponse> = (params) => {
  const { campaign_id, ...apiParams } = params;
  return get({
    endpoint: `influence/v2/projects/${campaign_id}/payouts/allowed_currencies`,
    params: apiParams,
    catchErrors: [422],
  });
};

// Payout recipients
export const fetchPayoutRecipient: (
  params: FetchPayoutRecipientParams,
) => ApiPromise<FetchPayoutRecipientResponse> = (params) => {
  const { profileId, ...apiParams } = params;
  return get({
    endpoint: `influence/v2/profiles/${profileId}/payout_recipient`,
    params: apiParams,
    catchErrors: [422],
  });
};
export const fetchPayoutRecipientsBatch: (
  params: FetchPayoutRecipientsBatchParams,
) => ApiPromise<FetchPayoutRecipientsBatchResponse> = (params) => {
  const { public_profile_ids } = params;
  return get({
    endpoint: `influence/v2/payout_recipients`,
    params: { public_profile_ids },
    catchErrors: [422, 404],
  });
};

export const updatePayoutRecipient: (
  params: UpdatePayoutRecipientParams,
) => ApiPromise<UpdatePayoutRecipientResponse> = (params) => {
  const { profileId, ...apiParams } = params;
  return post({
    endpoint: `influence/v2/profiles/${profileId}/payout_recipient`,
    params: apiParams,
    catchErrors: [422],
  });
};

// Credit wallet
export const creditWallet: (
  params: CreditWalletParams,
) => ApiPromise<CreditWalletResponse> = (params) => {
  return post({
    endpoint: `influence/v2/wallet_credit_requests`,
    params,
    catchErrors: [422],
  });
};

// Fetch wallets
export const fetchWallets: () => ApiPromise<FetchWalletsReponse> = () => {
  return get({
    endpoint: `influence/v2/wallets`,
    catchErrors: [422, 404],
  });
};

// Check if Payment enabled
export const checkPaymentApiStatus: () => ApiPromise<CheckPaymentApiStatusResponse> =
  () => {
    return get({
      endpoint: `influence/v2/salesforce_account`,
      catchErrors: [422],
    });
  };

// getPayoutsValidation
export const getPayoutsInformations: (
  params: GetPayoutsInformationsParams,
) => ApiPromise<GetPayoutsInformationResponse> = async ({
  campaign_id,
  validation_token,
  payout_ids,
}) => {
  const params = Object.assign(
    {},
    validation_token && { validation_token },
    payout_ids?.length && { payout_ids },
  );
  return get({
    endpoint: `influence/v2/projects/${campaign_id}/payouts/validation`,
    params,
    catchErrors: [404],
    additionalHeaders: {
      'Gigapay-Signature': await getValidationHash(params),
    },
  });
};

// confirmPayoutValidation
export const createPayoutValidation: (
  params: CreatePayoutValidationpParams,
) => ApiPromise<any> = async ({ campaign_id, payout_ids, option }) => {
  const params = { payout_ids, option };
  return post({
    endpoint: `influence/v2/projects/${campaign_id}/payouts/validation`,
    params,
    catchErrors: [],
    additionalHeaders: {
      'Gigapay-Signature': await getValidationHash(params),
    },
  });
};
// confirmPayoutValidation
export const verifyPayoutValidation: (
  params: VerifyPayoutValidationParams,
) => ApiPromise<any> = async ({ campaign_id, payout_ids, code }) => {
  const params = { payout_ids, code };
  return put({
    endpoint: `influence/v2/projects/${campaign_id}/payouts/validation`,
    params,
    catchErrors: [422],
    additionalHeaders: {
      'Gigapay-Signature': await getValidationHash(params),
    },
  });
};

// getBillingAddress
export const getBillingAddress: () => ApiPromise<BillingAddress> = () =>
  get({
    endpoint: `influence/v2/payment_integration`,
    debug: true,
    catchErrors: [404],
  });

// getBillingAddress
export const createBillingAddress: (
  params: Omit<BillingAddress, 'id'>,
) => ApiPromise<BillingAddress> = ({
  company_name,
  address_line_1,
  postal_code,
  city,
  country,
}) =>
  post({
    endpoint: `influence/v2/payment_integration`,
    params: {
      company_name,
      address_line_1,
      postal_code,
      city,
      country,
    },
    debug: true,
  });
